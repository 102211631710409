// extracted by mini-css-extract-plugin
export var button = "metamorphosis-listing-module--button--e1Zp0";
export var card = "metamorphosis-listing-module--card--jJqYf";
export var infiniteLoader = "metamorphosis-listing-module--infinite-loader--iLiL9";
export var intro = "metamorphosis-listing-module--intro--Rm+z1";
export var list = "metamorphosis-listing-module--list--QxUbx";
export var listHeader = "metamorphosis-listing-module--list-header--f8wVX";
export var listItem = "metamorphosis-listing-module--list-item--aesJC";
export var loaderBox = "metamorphosis-listing-module--loader-box--U5iUm";
export var noResultsBox = "metamorphosis-listing-module--no-results-box--jyeN7";
export var subtitle = "metamorphosis-listing-module--subtitle--rmaEm";
export var title = "metamorphosis-listing-module--title--q+sSc";
export var wrapper = "metamorphosis-listing-module--wrapper--zBvB1";