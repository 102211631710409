import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { getImage, withArtDirection } from 'gatsby-plugin-image';

import {
    banner,
    mainBanner,
    metamorphosis,
    metamorphosisList,
    videoOpinions,
} from './metamorfozy.module.scss';

import SEO from '../components/seo';
import Main from '../layouts/main';
import MainBanner from '../components/organisms/main-banner';
import MetamorphosisListing from '../components/organisms/metamorphosis-listing';
import VideoOpinions from '../components/organisms/video-opinions';

const MetamorphosisPage = () => {
    const data = useStaticQuery(query);

    const bannerImages = withArtDirection(getImage(data.bannerBg), [
        {
            image: getImage(data.bannerBgMobile),
            media: `(max-width: 419px)`,
        },
    ]);

    const bannerData = {
        fluidImg: bannerImages,
        title: 'Najlepsze metamorfozy Strefy Przemian',
        subtitle: `Poznaj naszych podopiecznych, którzy zrealizowali swój plan na 100% `,
    };

    return (
        <>
            <SEO title="Metamorfozy" />
            <Main transparentHeader={true} className={metamorphosis}>
                <section className={banner}>
                    <MainBanner
                        bannerData={bannerData}
                        className={mainBanner}
                        alt={`Banner strony "Metamorfozy"`}
                    />
                </section>
                <section className={metamorphosisList}>
                    <MetamorphosisListing />
                </section>
                <section className={videoOpinions}>
                    <VideoOpinions />
                </section>
            </Main>
        </>
    );
};

const query = graphql`
    {
        bannerBg: file(name: { eq: "metamorphosis-banner" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
            }
        }
        bannerBgMobile: file(name: { eq: "metamorphosis-banner-mobile" }) {
            childImageSharp {
                gatsbyImageData(width: 420, quality: 100, layout: CONSTRAINED, placeholder: BLURRED)
            }
        }
    }
`;

export default MetamorphosisPage;
