import * as React from 'react';

import Error from './error';
import FormControl from './form-control';

const ErrorForm = ({ children }) => (
    <FormControl>
        <Error>{children}</Error>
    </FormControl>
);

export default ErrorForm;
