import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
    title,
    wrapper,
    list,
    card,
    button,
    listItem,
    listHeader,
    infiniteLoader,
    loaderBox,
    subtitle,
    noResultsBox,
} from './metamorphosis-listing.module.scss';
import { getMetamorphosis } from '../../communication/metamorhosis';
import { config } from '../../config';

import Loader from '../atoms/loader';
import MetamorphosisCard from '../molecules/metamorphosis-card';
import Title from '../atoms/title';
import SubTitle from '../atoms/subtitle';
import Button from '../atoms/button';
import ErrorForm from '../atoms/form/error-form';
import LinkButton from '../atoms/link-button';

const MetamorphosisListing = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [hasMore, setHasMore] = useState(false);
    const [isInfiniteScrollDisabled, setIsInfiniteScrollDisabled] = useState(true);
    const [error, setError] = useState(null);
    const [isSeeMoreClicked, setIsSeeMoreClicked] = useState(false);
    const [isFirstFetchDone, setIsFirstFetchDone] = useState(false);

    const fetchData = () => {
        setIsLoading(true);
        getMetamorphosis(currentPage + 1, 5)
            .then((res) => {
                if (res.data.pagination.currentPage === 1) {
                    setIsFirstFetchDone(true);
                }
                setItems([...items, ...res.data.items]);
                setCurrentPage(res.data.pagination.currentPage);
                setHasMore(res.data.pagination.currentPage < res.data.pagination.pageCount);
            })
            .catch(() => {
                setHasMore(false);
                setError(config.messages.error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleSeeMoreClick = () => {
        setIsInfiniteScrollDisabled(false);
        setIsSeeMoreClicked(true);
        fetchData();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => fetchData(), []);

    return (
        <div className={wrapper}>
            <InfiniteScroll
                next={fetchData}
                hasMore={hasMore && !isInfiniteScrollDisabled}
                loader={
                    isLoading || (isLoading && isSeeMoreClicked) ? (
                        <Loader className={infiniteLoader} />
                    ) : null
                }
                dataLength={items.length}
                style={{ overflow: 'visible' }}
                scrollThreshold={0.5}
            >
                <div className={list}>
                    <div className={listHeader}>
                        <Title className={title}>
                            Nasi podopieczni, uwielbiają z nami współpracować! Poznaj ich oraz ich
                            przemiany!
                        </Title>
                        <SubTitle className={subtitle}>
                            Naszą misją i celem jest pomaganie w osiągnięciu wymarzonej sylwetki.
                            Nie robimy jednak tego w standardowy sposób.
                        </SubTitle>
                    </div>
                    {isLoading && !items.length && (
                        <div className={loaderBox}>
                            <Loader />
                        </div>
                    )}
                    {!isLoading && !items.length && !error && (
                        <div className={noResultsBox}>{config.messages.noResults}</div>
                    )}
                    {items.map((item, index) => {
                        return (
                            <div className={listItem} key={`metamorphosis-card-${index}`}>
                                <MetamorphosisCard
                                    cardData={item}
                                    className={card}
                                    aspectRatio={0.79}
                                />
                            </div>
                        );
                    })}
                </div>
                {error && <ErrorForm>{error}</ErrorForm>}
            </InfiniteScroll>
            {isInfiniteScrollDisabled && hasMore && (
                <Button color="yellow" onClick={handleSeeMoreClick} className={button}>
                    Poznaj wszystkie historie
                </Button>
            )}
            {!hasMore && isFirstFetchDone && (
                <LinkButton to="/cennik/" className={button} color="yellow">
                    Chcę dołączyć!
                </LinkButton>
            )}
        </div>
    );
};

export default MetamorphosisListing;
